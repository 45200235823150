import { FC, ReactElement, createContext, useCallback, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';

// third-party
import { IconX } from '@tabler/icons';
import { SnackbarProvider } from 'notistack';

// defaultTheme
import themes from 'themes';

// project imports
import { STORAGE_ANIMATION_TOGGLE, STORAGE_DNDMODE } from 'constants/store';
import useLocalStorage from 'hooks/useLocalStorage';
import NavigationScroll from 'layout/NavigationScroll';
import { AppState } from 'models/app';

export const UIContext = createContext<any>(null);

interface UIProviderProps {
    children: ReactElement;
}

interface ManagedUIContextProps {
    children: ReactElement;
}

export const UIProvider: FC<UIProviderProps> = ({ children }) => {
    const [animation, setAnimation] = useLocalStorage(STORAGE_ANIMATION_TOGGLE, true);
    const [dndmode, setDNDMode] = useLocalStorage(STORAGE_DNDMODE, false);

    const state = {
        dndmode,
        animation,
    };

    const actions = {
        setDNDMode,
        setAnimation,
    };

    const value = {
        ...state,
        ...actions,
    };

    return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUI = () => {
    const context = useContext(UIContext);
    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`);
    }
    return context;
};

export const ManagedUIContext: FC<ManagedUIContextProps> = ({ children }) => {
    const snackbarRef = useRef(null);
    const customization = useSelector((state: AppState) => state.customization);

    const renderSnackbarAction = useCallback(
        (id) => (
            <IconButton color="inherit" title="Close" onClick={() => (snackbarRef.current as any)?.closeSnackbar(id)}>
                <IconX stroke={1.5} size="1.3rem" />
            </IconButton>
        ),
        []
    );

    return (
        <UIProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <SnackbarProvider
                        ref={snackbarRef}
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        action={renderSnackbarAction}
                    >
                        <NavigationScroll>{children}</NavigationScroll>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </UIProvider>
    );
};
